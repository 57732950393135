import React, { useEffect, useRef, useState } from 'react';
import logo from './logo_small.png';
import './App.css';
import Help from './Help';

function App() {
  const [fleshlight, setFleshlight] = useState(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if(fleshlight) {
      document.body.classList.add('quadrat');
    } else {
      document.body.classList.remove('quadrat');
    }
  }, [fleshlight])

  var lang = navigator.language.split('-')[0];

  if(lang !== "hu") {
    lang = 'en';
  }

  const loadAsset = () => {
    fetch(`/assets/text-${lang}.json`)
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      })
  }

  useEffect(() => {
    loadAsset();
  }, []);


  return (
    <div className="App">
      <div className='header'>
      <a href="/"><img src={logo} alt=""/></a>
      </div>
      <div className="content">
        <Help />
      </div>
      <div className="footer">
        <div className="inner">
          <button className="warn-sign" onClick={() => setFleshlight(!fleshlight)}>{data.flash}</button>
          <p className="about"><a href="https://instagram.com/tothmeszti" target="_blank">Tóth Eszter</a> ötlete alapján<br />fejlesztette <a href="https://instagram.com/djenvo" target="_blank">Envo</a></p>
        </div>
      </div>
    </div>
  );
}

export default App;
