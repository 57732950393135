import React, { useEffect, useState } from 'react'

function Help() {

  const [level, setLevel] = useState('basefloor');
  const [area, setArea] = useState('');
  const [insult, setInsult] = useState('');

  const [mainItems, setMainItems] = useState<any[]>([]);
  const [baseFloorAreas, setBaseFloorAreas] = useState([]);
  const [danceFloorAreas, setDanceFloorAreas] = useState([]);
  const [insults, setInsults] = useState([]);
  const [data, setData] = useState<any>([]);

  var lang = navigator.language.split('-')[0];

  if(lang !== "hu") {
    lang = 'en';
  }

  //lang = 'hu';

  const loadAsset = () => {
    fetch(`/assets/text-${lang}.json`)
      .then((res) => res.json())
      .then((res) => {
        setMainItems(res.mainItems);
        setBaseFloorAreas(res.baseFloorAreas);
        setDanceFloorAreas(res.danceFloorAreas);
        setInsults(res.insults);
        setData(res);
      })
  }

  const sendAlarm = () => {
    if(level && area && insult) {
      fetch('https://api.kinkster.hu/party/sendalarm', {  
        mode: 'cors',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
        body: JSON.stringify({
          level,
          area,
          insult
        })
      })
    }
  }

  useEffect(() => {
    loadAsset();
  }, []);

  useEffect(() => {
    sendAlarm();
  },[insult]);

  const renderLevels = () => {
    return (
      <>
        {mainItems.map(main => <button className='wide' onClick={() => setLevel(main.id)}>{main.title} <span className='forward'>»</span></button>)}
      </>
    )
  }

  const renderAreas = () => {
    if(level === 'basefloor') {
      return (
        <>
          {baseFloorAreas.map(area => <button className='wide' onClick={() => setArea(area)}>{area} <span className='forward'>»</span></button>)}
        </>
      )
    }
    if(level === 'dancefloor') {
      return (
        <>
          {danceFloorAreas.map(area => <button className='wide' onClick={() => setArea(area)}>{area} <span className='forward'>»</span></button>)}
        </>
      )
    }
  }


  return (
    <div className='help-menu'>
      <div>
      {level === '' && (
        <>
          <div className='report-start'>
            <p>{data.intro}</p>
            <br />
            <p><strong>{data.intro2}</strong></p>
          </div>
          <h3>{data.title1}</h3>
          {renderLevels()}
        </>
      )}
      {level !== '' && area === '' && (
        <>
          <h3>{data.title2}</h3>
          {/* <button className='wide back' onClick={() => setLevel('')}><span className='backward'>«</span> {data.back}</button> */}
          {renderAreas()}
        </>
      )}
      {level !== '' && area !== '' && insult === '' && (
        <>
          <h3>{data.title3}</h3>
          <button className='wide back' onClick={() => setArea('')}><span className='backward'>«</span> {data.back}</button>
          {insults.map(insult => <button className='wide' onClick={() => setInsult(insult)}>{insult}</button>)}
        </>
      )}
      {level !== '' && area !== '' && insult !== '' && (
        <div className='report-done'>
          <h2>{data.reportDone}</h2>
          <p>{data.reportText}</p>
        </div>
      )}
      </div>
    </div>
  )
}

export default Help